import { Container, Grid, Typography, Box, Button } from '@mui/material';
import { getImageUrl } from '../../utils';

const personalTrainingImage = getImageUrl('pt-3.png');

const PersonalTraining = () => (
  <Container sx={{ padding: { xs: '3rem 1rem', md: '4rem 2rem' }, backgroundColor: '#f9f9f9' }}>
    <Grid container spacing={6} alignItems="center">
      <Grid item xs={12} md={6}>
        <Typography 
          variant="h4" 
          sx={{ fontWeight: 'bold', color: '#333', marginBottom: '1.5rem', fontSize: { xs: '2rem', md: '2.5rem' } }}
        >
          Personlig Träning
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ color: '#555', lineHeight: '1.8', marginBottom: '2.5rem', fontSize: { xs: '1rem', md: '1.125rem' }, maxWidth: '95%' }}
        >
          Vi erbjuder skräddarsydd personlig träning som hjälper dig att nå dina individuella träningsmål på ett säkert och effektivt sätt.
        </Typography>
        <Button 
          variant="contained" 
          sx={{ backgroundColor: '#00b5bf', padding: { xs: '0.75rem 1.5rem', md: '0.75rem 2rem' }, fontSize: { xs: '0.875rem', md: '1rem' }, borderRadius: '8px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', textTransform: 'none', '&:hover': { backgroundColor: '#007C85', boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.2)' }}}
          href="/sidepages/personaltraining-info"
        >
          Se Mer
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{ backgroundImage: `url(${personalTrainingImage})`, backgroundSize: 'cover', backgroundPosition: 'center', height: { xs: '250px', md: '400px' }, borderRadius: '12px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.03)' }}}
        />
      </Grid>
    </Grid>
  </Container>
);

export default PersonalTraining;
