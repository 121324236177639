// src/components/TopBanner.js
import { Box, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const TopBanner = () => (
  <Box sx={{ 
    backgroundColor: '#00b5bf', 
    padding: { xs: '0.5rem 0', md: '0.75rem 0' }, 
    textAlign: 'center', 
    color: '#fff', 
    fontSize: { xs: '12px', md: '14px' }, 
    letterSpacing: '0.5px' 
  }}>
    <Typography variant="body2" sx={{ 
      display: 'inline-flex', 
      alignItems: 'center', 
      gap: { xs: '0.5rem', md: '1rem' }, 
      flexWrap: 'wrap', 
      justifyContent: 'center' 
    }}>
      <a href="mailto:info@pilateshoganas.se" style={{ 
        color: '#fff', 
        textDecoration: 'none', 
        display: 'inline-flex', 
        alignItems: 'center' 
      }}>
        <EmailIcon fontSize="small" sx={{ marginRight: '0.25rem' }} /> 
        info@pilateshoganas.se
      </a> 
      <span>|</span>
      <a href="tel:0707945209" style={{ 
        color: '#fff', 
        textDecoration: 'none', 
        display: 'inline-flex', 
        alignItems: 'center' 
      }}>
        <PhoneIcon fontSize="small" sx={{ marginRight: '0.25rem' }} /> 
        0707945209
      </a>
    </Typography>
  </Box>
);

export default TopBanner;
