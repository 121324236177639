// src/components/AboutInfo.js
import { Container, Typography, Box, Grid } from '@mui/material';
import { getImageUrl } from '../../utils';

const AboutInfo = () => (
  
  <Container sx={{ padding: '4rem 0', maxWidth: 'lg' }}>
    {/* Title Section */}
    <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#007C85' }}>
      Om Pilates Höganäs
    </Typography>

    {/* Why Train Pilates Section */}
    <Box sx={{ marginTop: '3rem' }}>
      <Grid container spacing={4} alignItems="center">
        {/* Text on the Left, Image on the Right */}
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#007C85' }}>
            Varför Träna Pilates?
          </Typography>
          <Typography variant="body1" paragraph sx={{ lineHeight: 1.7, color: '#555' }}>
            Pilates är en fantastisk träningsform som stärker kärnmuskulaturen, förbättrar flexibiliteten och främjar en sund kroppshållning. Det är en skonsam men effektiv träning som passar alla, oavsett ålder eller fysisk kondition.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            src={getImageUrl('about-quote.png')}
            alt="Why Train Pilates"
            style={{ width: '100%', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
          />
        </Grid>
      </Grid>
    </Box>
    

    {/* Our Studio Section */}
    <Box sx={{ marginTop: '3rem' }}>
      <Grid container spacing={4} alignItems="center">
        {/* Image on the Left, Text on the Right */}
        <Grid item xs={12} md={6}>
          <img
            src={getImageUrl('about-studio2.png')}
            alt="Pilates Studio Interior"
            style={{ width: '100%', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#007C85' }}>
            Vår Studio
          </Typography>
          <Typography variant="body1" paragraph sx={{ lineHeight: 1.7, color: '#555' }}>
          Vi har nyligen flyttat till en ny och större studio, och vi kunde inte vara mer exalterade! Här möts du av en modern men mysig atmosfär där varje hörn andas träning och välmående. Studion är full av ny, glänsande utrustning – perfekt för att ge dig den bästa träningsupplevelsen. Våra tränare? De är inte bara högutbildade och proffsiga, de vet också hur man gör träning rolig! Oavsett om du är nybörjare eller en erfaren pilatesfantast, så har vi klasser som passar alla nivåer. Och för dig som vill ha något extra erbjuder vi även personliga PT-pass – för det där lilla extra fokuset på just dina behov.          </Typography>

        </Grid>
      </Grid>
    </Box>
    <Box sx={{ marginTop: '3rem' }}>
      <Grid container spacing={4} alignItems="center">
        {/* Text on the Left, Image on the Right */}
        <Grid item xs={12} md={6}>
          
          <Typography variant="body1" paragraph sx={{ lineHeight: 1.7, color: '#555' }}>
          Vi vill att Pilates Höganäs ska kännas som ditt andra hem. Vår studio har en vacker och inbjudande inredning där du gärna kan stanna kvar för att umgås, ta en kopp kaffe och njuta av atmosfären, både före och efter din klass. Vi ligger nu i lokala padelhallen, som har en fantastisk restaurang – perfekt för att avrunda dagen med något gott efter ett härligt träningpass!</Typography>
        </Grid>
        <Grid item xs={12} md={5}>
          <img
            src={getImageUrl('new-about1.png')}
            alt="Why Train Pilates"
            style={{ width: '100%', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
          />
        </Grid>
      </Grid>
      <Box sx={{ marginTop: '3rem' }}>
        <Grid container spacing={5} justifyContent="center">
        <Grid item xs={3}>
            <img
              src={getImageUrl('new-about3.png')}
              alt="Studio Image 2"
              style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}
            />
          </Grid>
          <Grid item xs={3}>
            <img
              src={getImageUrl('newest-props.png')}
              alt="Studio Image 1"
              style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}
            />
          </Grid>
        
          <Grid item xs={3}>
            <img
              src={getImageUrl('new-about2.png')}
              alt="Studio Image 3"
              style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}
            />
          </Grid>
          <Grid item xs={3}>
            <img
              src={getImageUrl('newest-ball.png')}
              alt="Studio Image 4"
              style={{ width: '100%', borderRadius: '8px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
    

    {/* Get to Know Our Trainers Section */}
    <Box sx={{ marginTop: '5rem' }}>
      <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#007C85', marginBottom: '3rem' }}>
        Pilates Höganäs Instruktörer
      </Typography>

      {/* Trainer Sections */}
      {[
        {
          name: 'Sandra Möller',
          imageUrl: 'about-sandra3.png',
          description: `
            Jag startade min pilatesresa 2005 och gjorde mina första utbildningar inom klassisk pilates tillsammans med Powerpilates. 
            Först all mattutbildning upp till Challenge nivå och sen den stora apparatutbildningen Comprehensive. 
            Efter det gick jag över till The Pilates Standard och gjorde deras Comprehensive.
            2017 var jag en av de första i världen som fick gå the Leap med legenden Bob Liekens. 
            Med den äran certifierades jag där av Bob till Teacher Trainer. 
            Jag blev kär i Pilates när jag tack vare träningen blev smärtfri, starkare och smidigare. 
            Dessutom presterade jag väldigt mycket bättre som tävlingsryttare och mina hästar blev sundare tack vare att jag var mer i balans, med andra ord Win Win! 
            Alla underbara kunder, oändliga variationer av övningar och känslan som jag känner i min kropp gör att jag aldrig tröttnar. 
            Jag är privilegierad som får jobba med det jag tycker är roligast i världen.`,
          certifications: `
            Certifierad comprehensive teacher,
            Ceritifierad Teacher Trainer,
            Certifierad TRX instruktör,
            Certifierad BALLance instruktör,
            Certifierad Functional Trainer 2.0 - Level 1, Nordic Edition, Eleiko,
            Certifierad C-tränare inom hästhoppning,
            Always a student, 
            Sandra
          `,
          imageSide: 'right'
        },
        {
          name: 'Agnes Knutsson',
          imageUrl: 'about-agnes3.png',
          description: `
            Min passion för träning har funnits i min kropp sedan tonåren då jag tävlingssimmade och tillbringade stora delar av min tid på aerobicspass. 
            När min rygg för cirka 15 år sedan brakade ihop började min pilatesresa. 
            Jag insåg snabbt hur denna träningsform kan påverka både kropp och sinne. Det blev en självklarhet att jag skulle fördjupa mina kunskaper inom pilates.
          `,
          certifications: `
            Certifierad mattinstruktör,
            Certifierad comprehensive teacher,
            Certifierad TRX-instruktör,
            Certifierad BALLance-instruktör,
          `,
          imageSide: 'left'
        },
        {
          name: 'Johanna Magnelin',
          imageUrl: 'about-johanna3.png',
          description: `
            Jag är ingen träningsentusiast, lite lat faktiskt och helt prestigelös i min träning. Däremot gillar jag allt som jag mår bra av, det som ger styrka, energi och glädje. 
            Det känner jag när jag själv kör ett pass och som instruktör på Pilates Höganäs.
            När vi ses i studion är jag med dig i varje andning och rörelse. Gläds åt dina framsteg och hejar på när det är motigt. Roligaste lagsporten jag ägnat mig åt!
          `,
          certifications: `
            Comprehensive Pilates Teacher,
            Certifierad BALLance-instruktör
          `,
          imageSide: 'right'
        },
        {
          name: 'Malin Heimdahl',
          imageUrl: 'about-malin3.png',
          description: `
            Jag lever mycket efter lustprincipen. Det som är roligast, ger flest skratt, glädje, kärlek och energi går först. Resten får vänta. 
            Detta gäller så klart även all träning och för mig är det nyckeln till att fortsätta träna! 
            Med bakgrund inom yogan låter jag små frön från den smyga sig in i uppvärmning och avslut i pilatesträningen. 
            På mina yogapass varvar vi lugn yin och meditation med mer krävande övningar från hatha och ashtanga.
          `,
          certifications: `
            Certifierad Yogalärare, 
            Certifierad Gravidyogainstruktör, 
            Certifierad TRX-instruktör, 
            Certifierad BALLance-instruktör, 
            Pilates-instruktör under utbildning hos Sandra.
          `,
          imageSide: 'left'
        },
        {
          name: 'Jennie Levin',
          imageUrl: 'about-jennie3.png',
          description: `
            Jag kom i kontakt med Pilates under min första graviditet 2005.

            Inom träning har jag förr varit en periodare, tränat intensivt för att sen belöna mig (när resultaten började komma) med soffhäng 🙃

            2015 bestämde jag mig för att själv bli instruktör inom träning. Dels för att få ett jämnare flöde i min egen träning, men även för att få dela träningsglädje och att kunna inspirera andra till hälsa och välmående 🙂 

            Jag gillar variation i träning, och tycker att både pilates och Trx går att variera fullt ut. 
            Det ger flås, styrka, ökad rörlighet, stretch och är även skonsamt för kroppen! 

            Jag är den mest morgonpigga av oss instruktörer 😂 iallafall enligt schema 😉 
            ven om det ibland kan vara motigt när klockan ringer tiiidigt, så är det riktigt skönt när man väl börjar passet, och framförallt när det är avklarat 😉 

          `,
          certifications: `
            Certifierad TRX-instruktör, 
            Certifierad Body Pump instruktör enligt Les Mills, Tabata/crosschallenge/cage, Bootymove, Core
            Certifierad pilates-instruktör
            
            
          `,
        
          imageSide: 'right'
        },
        
      ].map((trainer, index) => (
        <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ marginBottom: '4rem' }} key={index}>
          {trainer.imageSide === 'left' && (
            <Grid item xs={12} md={4}>
              <img
                src={getImageUrl(trainer.imageUrl)}
                alt={trainer.name}
                style={{ width: '100%', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
              />
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', color: '#007C85' }}>
              {trainer.name}
            </Typography>
            <Typography variant="body1" paragraph sx={{ lineHeight: 1.7, color: '#555' }}>
              {trainer.description}
            </Typography>
            <Typography variant="body1" paragraph sx={{ lineHeight: 1.7, color: '#555' }}>
              {trainer.certifications}
            </Typography>
          </Grid>
          {trainer.imageSide === 'right' && (
            <Grid item xs={12} md={4}>
              <img
                src={getImageUrl(trainer.imageUrl)}
                alt={trainer.name}
                style={{ width: '100%', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
              />
            </Grid>
          )}
        </Grid>
      ))}
    </Box>
    {/* Where Are We Section */}
    <Box sx={{ marginTop: '3rem' }}>
      <Grid container spacing={4} >
        {/* Image on the Left, Text on the Right */}
      
        <Grid item xs={12} md={6}>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', color: '#007C85' }}>
            Var Finns Vi?
          </Typography>
          <Typography variant="body1" paragraph sx={{ lineHeight: 1.7, color: '#555' }}>
            Du hittar oss på Litteraturvägen 4A, 263 35 Höganäs. Vår studio ligger centralt, med bra parkeringsmöjligheter och nära kollektivtrafik.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d21249.472897457284!2d12.5511461!3d56.1923911!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465265f45b9b483d%3A0xe1f3e5bbf235a1e2!2sLitteraturv%C3%A4gen%204A%2C%20263%2035%20H%C3%B6gan%C3%A4s%2C%20Sweden!5e0!3m2!1sen!2sse!4v1632992896581!5m2!1sen!2sse"
            width="100%"
            height="400px"
            style={{ border: 'none', borderRadius: '12px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
            allowFullScreen=""
            loading="lazy"
            title="Studio Location"
          ></iframe>
        </Grid>
      </Grid>
    </Box>
  </Container>
);

export default AboutInfo;
