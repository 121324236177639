import { Container, Grid, Typography, Box, Button } from '@mui/material';
import { getImageUrl } from '../../utils';
import { useEffect } from 'react';

const personalTrainingImages = [
  getImageUrl('pt-sandra.png'),
  getImageUrl('pt-3.png'),
  getImageUrl('pt-1.png')
];

const PersonalTrainingInfo = () => (
  
  <Container sx={{ padding: '4rem 0', backgroundColor: '#f9f9f9' }}>
    
    <Typography 
      variant="h4" 
      sx={{ fontWeight: 'bold', color: '#333', marginBottom: '2rem', textAlign: 'center', fontSize: { xs: '2rem', md: '2.5rem' } }}
    >
      Personlig Träning
    </Typography>
    
    <Typography 
      variant="body1" 
      paragraph 
      sx={{ color: '#555', marginBottom: '3rem', lineHeight: '1.8', textAlign: 'center', fontSize: { xs: '1rem', md: '1.125rem' }, maxWidth: '800px', margin: '0 auto' }}
    >
      Upptäck fördelarna med personlig träning som är helt anpassad efter dina behov och mål. Våra certifierade tränare hjälper dig att nå nya höjder genom professionell vägledning, oavsett om du är nybörjare eller erfaren.
    </Typography>

    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '3rem', marginBottom:'3rem' }}>
      <Button 
        variant="contained" 
        sx={{ 
          backgroundColor: '#A2257C', 
          color: '#fff', 
          padding: '0.75rem 2.5rem', 
          fontSize: { xs: '0.875rem', md: '1rem' }, 
          borderRadius: '12px', 
          textTransform: 'none', 
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', 
          '&:hover': { backgroundColor: '#A2557C', boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.2)' }
        }}
        href="https://pilateshoganas.wondr.se/w_booking/wizards/book/600833b3-4b08-4acb-9202-001c0a1000ef"
      >
        Boka Personlig Träning
      </Button>
    </Box>

    <Grid container spacing={4} justifyContent="center">
      {personalTrainingImages.map((image, index) => (
        <Grid item xs={12} md={6} key={index}>
          <Box
            sx={{ 
              backgroundImage: `url(${image})`, 
              backgroundSize: 'cover', 
              backgroundPosition: 'center', 
              height: { xs: '350px', md: '460px' }, 
              borderRadius: '12px', 
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' 
            }}
          />
        </Grid>
      ))}
    </Grid>

    
  </Container>
);

export default PersonalTrainingInfo;
