import React from 'react';
import { Container, Grid, Typography, Card, CardContent, CardMedia, Box, Button } from '@mui/material';
import { getImageUrl } from '../../utils';

const sockImage = getImageUrl('about-socks.png'); // Use the getImageUrl function for the socks image

const classesData = [
  {
    title: 'Intro Tower',
    where: 'Stora Salen',
    passlength: '55m',
    description: 'För dig som vill lära dig grunderna inom Klassisk Pilates. Vi kör 5 tillfällen och förbereder dig för att kunna gå in och köra våra ordinarie klasser på ett tryggt och effektivt sätt.',
    image: getImageUrl('new-tower3.png'),
  },
  {
    title: 'Intro Wunda Chair',
    where: 'PT-rummet',
    passlength: '45m',
    description: 'Träna Wunda Chair i grupp om 6 personer. Styrka, stabilitet, stretch och uthållighet! Du kommer hitta muskler i hela kroppen som du inte visste fanns!',
    image: getImageUrl('pilates-wunda.png'),
  },
  {
    title: 'Intro Reformer',
    where: 'Stora Salen',
    passlength: '55m',
    description: 'Träna reformer i grupp om 6 personer. Här lär vi dig grunderna på, enligt många, den roligaste pilatesapparaten som finns. Kropp och knopp får jobba när du lär dig en viss ordningsföljd, hur du ska manövrera de olika fjädrarna samtidigt som du ligger, sitter och står på reformern. Måste upplevas, så himla kul och utmanande. Det går verkligen inte att tänka på annat de 55 minuterna!',
    image: getImageUrl('klasses-reformer.png'),
  },
  {
    title: 'Tower Öppen',
    where: 'Stora Salen',
    passlength: '45m eller 55m',
    description: 'En öppen Tower-klass. Instruktören anpassar passet efter kunderna. Olika props som bollar, ringar, hantlar, foam rollers etc kan också förekomma. Roligt, utmanade och för alla!',
    image: getImageUrl('new-tower5.png'),
  },
 
  {
    title: 'Tower I',
    where: 'Stora Salen',
    passlength: '45m eller 55m',
    description: 'Här lägger vi oss på en nivå som vi vill att alla klarar av och tycker är roligt.',
    image: getImageUrl('new-tower2.png'),
  },
  {
    title: 'Tower II',
    where: 'Stora Salen',
    passlength: '55m',
    description: 'Här kan allt hända! För dig som har stenkoll på din pilates och vill bli utmanad! Inget för rookies, fråga din instruktör om du är redo innan du bokar',
    image: getImageUrl('new-tower6.png'),
  },
  {
    title: 'Reformer I',
    where: 'Lilla Salen',
    passlength: '45m eller 55m',
    description: 'Har du gått reformer Intro är detta klassen för dig! Krävs att du har gjort kurs eller går pt, varmt välkommen!',
    image: getImageUrl('reformer2.png'),
  }, 
  {
    title: 'Reformer II',
    where: 'Lilla Salen',
    passlength: '45m eller 55m',
    description: 'Här kan allt hända! För dig som har stenkoll på din pilates och vill bli utmanad! Inget för rookies, fråga din instruktör om du är redo innan du bokar',
    image: getImageUrl('reformer-i.png'),
  },
  {
    title: 'Barrels',
    where: 'Stora Salen',
    passlength: '45m',
    description: 'Ett intensivt pass på våra Small Barrels! Utmana dig själv genom att balansera upp uppe på våra ”halva öltunnor”. Kräver mycket styrka och att pilates-övningarna sitter. Roligt och avslöjande!',
    image: getImageUrl('barrels-new.png'),
  },
  {
    title: 'Wunda I',
    where: 'PT-rummet',
    passlength: '45m',
    description: 'En klass för alla! Först värmer vi upp med klassisk matta och sen tränar vi på den berömda pilates-stolen!',
    image: getImageUrl('new-wunda1.png'),
  },
  {
    title: 'Wunda II',
    where: 'PT-rummet',
    passlength: '45m',
    description: 'Här kan allt hända! För dig som har stenkoll på din pilates och vill bli utmanad! Inget för rookies, fråga din instruktör om du är redo innan du bokar',
    image: getImageUrl('pilates-wunda3.png'),
  },
  {
    title: 'Klassisk Matta',
    where: 'Stora Salen',
    passlength: '50m',
    description: 'Från början Joseph Pilates hemläxa till sina kunder. Det finns 36 olika övningar att tillgå och ju mer Pilates du gör, desto mer uppskattar du mattan. Den ultimata kraftansträngningen där du utmanar dig själv utan några hjälpmedel! Hard core och för alla!',
    image: getImageUrl('newest-props.png'),
  },
  
  {
    title: 'Yoga',
    where: 'Stora Salen',
    passlength: '60m',
    description: 'Dynamisk yoga för alla, oavsett yogaerfarenhet. En perfekt vän till pilatesträningen för att få en helhet i kropp och sinne. Enkelt översatt betyder yoga förening. Det kan ses som en förening av kropp & sinne, yttre & inre, fysiskt & mentalt. Yogan är din, handlar inte om perfekta positioner, utan om din resa, hur du mår på matten, här och nu och lite vart vi är på väg. Yogaklasserna är ofta en mix av olika yogaformer med inslag av bla yin, hatha, vinyasa & mediyoga. Ibland lugnare, ibland svettigt och tufft. Vi kan också nosa på andningsövningar och meditation. Välkommen!',
    image: getImageUrl('new-yoga2.png'),
  },
  {
    title: 'Gravid Yoga',
    where: 'Stora Salen',
    passlength: '60m',
    description: 'Vill du stärka kroppen under graviditeten, förbreda dig fysiskt och mentalt inför förlossningen och tiden efteråt? I gravidyoga arbetar vi med styrka, rörlighet, stretch, stabilitet, bäckenbottenträning, medveten närvaro, andning och avslappning',
    image: getImageUrl('new-yoga.png'),
  },
  
  {
    title: 'BALLance',
    where: 'Lilla Salen eller Stora Salen',
    passlength: '55m',
    description: 'BALLance passar både dig som har ont/spänningar i rygg, nacke, axlar, svank, höfter - eller dig som tränar mycket och behöver få hjälp att släppa på spänningar. BALLance är ett pass där du ligger ner på patenterade bollar och arbetar medvetet med din andning. Bollarna placeras på olika ställen under ryggen och andra delar av kroppen för att ge dekompression, släppa spänningar och förbättra din andning.',
    image: getImageUrl('new-ball.png'),
  },
  
  {
    title: 'TRX',
    where: 'Stora SalenLilla',
    passlength: '45m',
    description: 'TRX-klasserna är öppna klasser. TRX är utvecklat för effektiv allsidig träning. Med kroppen som motvikt bygger du muskler och bränner fett samt ökar din rörlighet. Här inspireras vi av studions andra träningsformer samtidigt som vi lägger till flås!',
    image: getImageUrl('pilates-trx2.png'),
  },
  
  
  
];

const Klasses = () => (
  <Container sx={{ padding: '4rem 0', backgroundColor: '#f9f9f9' }}>
    <Typography 
      variant="h3" 
      align="center" 
      sx={{ 
        fontWeight: 'bold', 
        marginBottom: '3rem', 
        color: '#007C85',
        textTransform: 'uppercase',
        letterSpacing: '0.1em'
      }}
    >
      Våra Klasser
    </Typography>
    <Box>
    <Typography variant="body1" sx={{ fontSize: '1.1rem', marginBottom:'4rem', paddingLeft:'4rem', paddingRight:'4rem', textAlign:'center' }}>
          Här hittar du korta beskrivningar av våra klasser. <br/> Om du har några frågor om vilken klass som passar dig bäst, tveka inte att kontakta oss!
        </Typography>
    </Box>
    
    <Box sx={{ 
      display: 'flex', 
      flexDirection: { xs: 'column', sm: 'row' }, 
      alignItems: 'center', 
      justifyContent:'center', 
      marginBottom: '2rem',
      textAlign: 'center'
    }}>
      <Typography variant="body1" sx={{ fontSize: '1rem', maxWidth: '15rem' ,marginBottom: { xs: '1rem', sm: '0' }, marginRight: { sm: '1rem' } }}>
        Observera att alla deltagare måste bära strumpor under klasserna. <br/><br/> Klart har vi strumpor till försäljning i olika prisklasser!
      </Typography>
      <img
        src={getImageUrl('new-about2.png')} // Using the getImageUrl function for the socks image
        alt="Socks"
        style={{
          width: '100%', // Full width for mobile screens
          maxWidth: '15rem', // Limit max width for larger screens
          height: 'auto',
          borderRadius: '12px',
          marginLeft: { xs: '0', sm: '1rem' }, // Adjust margin for responsiveness
        }}
      />
    </Box>
    <Box sx={{ textAlign: 'center', marginTop: '3rem' }}>
      <Button
        sx={{
          backgroundColor: '#A2257C',
          color: '#fff',
          padding: '0.5rem 1rem',
          marginBottom: '2rem',
          fontSize: '1rem',
          fontWeight: '600',
          borderRadius: '12px',
          textTransform: 'none',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          '&:hover': {
            backgroundColor: '#A2557C',
          },
        }}
        href="/sidepages/membership-info"
      >
        Köp Medlemskap
      </Button>
    </Box>
    
    <Grid container spacing={4}>
      {classesData.map((klass, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Card sx={{ 
            marginLeft:'1rem',
            marginRight:'1rem',
            boxShadow: '0 6px 20px rgba(0,0,0,0.1)', 
            borderRadius: '12px',
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'translateY(-10px)',
              boxShadow: '0 12px 30px rgba(0, 0, 0, 0.2)',
            }
          }}>
            <CardMedia
              component="img"
              height="400"
              image={klass.image}
              alt={klass.title}
              sx={{ borderRadius: '12px 12px 0 0', objectFit: 'cover' }}
            />
            <CardContent sx={{ padding: '1.5rem' }}>
              <Typography 
                variant="h6" 
                sx={{ 
                  fontWeight: 'bold', 
                  marginBottom: '0.5rem', 
                  color: '#007C85',
                  textTransform: 'uppercase',
                  letterSpacing: '0.05em'
                }}
              >
                {klass.title}
              </Typography>
              <Typography 
                variant="body2" 
                color="textSecondary" 
                sx={{ color: '#666', fontSize:'16px' }}
              >
                {klass.description}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
    
    
  </Container>
);

export default Klasses;
