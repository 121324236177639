import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Box, Container, Grid, Card, CardActionArea, CardMedia, Typography, CardContent } from '@mui/material';

const Instafeed = () => {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url&access_token=IGQWRNWVpPaEJONDhsT3lieVdFV2FxWEd0YTBuQUlacnFJRHdhZA1h6cFZAReGxNb0gydjJvRGRUMk5ESFhNS21YWUFjMDBra1p4RnQ5ZAGs4VFphVkVtb2hnbWhEanBFbVROYVRncWNCazdzV1FRQW45TDlmY25jLXMZD`
        );
        const latestPosts = response.data.data.slice(0, 3); // Get only the latest 3 posts
        setPosts(latestPosts);
      } catch (error) {
        console.error('Error fetching Instagram posts', error);
        setError('Could not load Instagram feed.');
      }
    };

    fetchPosts();
  }, []);

  return (
    <Container sx={{ padding: '4rem 0', backgroundColor: '#f9f9f9' }}>
      <Typography
        variant="h4"
        align="center"
        sx={{
          fontWeight: 'bold',
          color: '#333',
          marginBottom: '2rem',
          textTransform: 'uppercase',
          letterSpacing: '0.1em',
        }}
      >
        Senaste från Instagram
      </Typography>
      
      {error ? (
        <Typography
          variant="body1"
          align="center"
          sx={{ color: '#f00', marginBottom: '2rem' }}
        >
          {error}
        </Typography>
      ) : (
        <Grid container spacing={4} justifyContent="center">
          {posts.map((post) => (
            <Grid item xs={12} sm={6} md={4} key={post.id}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'translateY(-10px)',
                    boxShadow: '0 12px 30px rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <CardActionArea
                  href={post.permalink}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ height: '100%' }}
                >
                  {post.media_type === 'IMAGE' || post.media_type === 'CAROUSEL_ALBUM' ? (
                    <CardMedia
                      component="img"
                      height="300"
                      image={post.media_url}
                      alt={post.caption}
                      sx={{ objectFit: 'cover' }}
                      loading="lazy" // Lazy loading for better performance
                    />
                  ) : post.media_type === 'VIDEO' ? (
                    <CardMedia
                      component="video"
                      controls
                      height="300"
                      src={post.media_url}
                      sx={{ objectFit: 'cover' }}
                    />
                  ) : null}
                  <CardContent sx={{ backgroundColor: '#f6f6f6', color: '#333', p: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontSize: '16px',
                        fontWeight: '400',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        mb: '1rem',
                      }}
                    >
                      {post.caption.split('\n')[0]} {/* Display only the first line of the caption */}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default Instafeed;
