import { Container, Typography, Grid, Box, Button } from '@mui/material';
import { getImageUrl } from '../../utils';

const aboutImage = getImageUrl('pilates-group3.png');

const About = () => (
  <Container 
    sx={{ 
      padding: { xs: '3rem 1rem', md: '4rem 2rem' }, // Adjusted padding for mobile and desktop
      backgroundColor: '#f9f9f9', 
    }}
  >
    <Grid container spacing={6} alignItems="center">
      <Grid item xs={12} md={6}>
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 'bold', 
            color: '#333', 
            marginBottom: '1.5rem', // Increased margin for spacing
            fontSize: { xs: '2rem', md: '2.5rem' } // Responsive font size
          }}
        >
          Välkommen till Pilates Höganäs
        </Typography>
        <Typography 
          variant="body1" 
          sx={{ 
            color: '#555', 
            lineHeight: '1.8', 
            marginBottom: '2.5rem', // Increased margin for spacing
            fontSize: { xs: '1rem', md: '1.125rem' }, // Adjusted for better readability
            maxWidth: '95%', // Limits text width for readability
          }}
        >
          Vi erbjuder personlig träning, towerklasser och klassiska mattklasser i olika nivåer.
        </Typography>
        <Button 
          variant="contained" 
          sx={{ 
            backgroundColor: '#00b5bf', 
            padding: { xs: '0.75rem 1.5rem', md: '0.75rem 2rem' }, // Responsive padding
            fontSize: { xs: '0.875rem', md: '1rem' }, // Responsive button text size
            borderRadius: '8px', // Slightly rounded corners for a modern look
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Light shadow for depth
            textTransform: 'none', // Keeps text as is, without uppercasing
            '&:hover': {
              backgroundColor: '#007C85',
              boxShadow: '0px 6px 16px rgba(0, 0, 0, 0.2)', // Enhanced shadow on hover
            }
          }} 
          href="/sidepages/about-info"
        >
          Se mer
        </Button>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            backgroundImage: `url(${aboutImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            height: { xs: '250px', md: '400px' }, // Adjusted height for responsiveness
            borderRadius: '12px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            transition: 'transform 0.3s ease-in-out', // Smooth hover animation
            '&:hover': {
              transform: 'scale(1.03)', // Slight zoom on hover for interactivity
            },
          }}
        />
      </Grid>
    </Grid>
  </Container>
);

export default About;
