import { Grid, Typography, Box, TextField, Button } from '@mui/material';
import { useState } from 'react';
import emailjs from 'emailjs-com'; // Import EmailJS
import { getImageUrl } from '../../utils';

const contactImage = getImageUrl('about-3.png');

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',  // Added phone field
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Replace these with your own EmailJS credentials
    const serviceID = 'service_0dt32u7';
    const templateID = 'template_zvbgn39';
    const userID = 'SxRsBdlgUaGAGjDZh';

    // Prepare the template parameters
    const templateParams = {
      to_name: 'Pilates Höganäs',  // Replace with the recipient's name if needed
      from_name: formData.name,
      from_phone: formData.phone,
      from_email: formData.email,
      message: formData.message,
    };

    emailjs.send(serviceID, templateID, templateParams, userID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        alert('Message sent successfully!');
        setFormData({ name: '', email: '', phone: '', message: '' });  // Clear form after sending
      })
      .catch((error) => {
        console.error('FAILED...', error);
        alert('An error occurred, please try again.');
      });
  };

  return (
    <Box sx={{ padding: { xs: '2rem 1rem', md: '4rem 2rem' }, backgroundColor: '#fff' }}>
      <Grid container spacing={4} justifyContent="center">
        {/* Contact Form Section */}
        <Grid item xs={12} md={6}>
          <Typography
            variant="h4"
            sx={{
              color: '#222',
              marginBottom: '1.5rem',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '0.1em',
              textAlign: 'center',
              fontSize: { xs: '1.5rem', md: '2rem' }
            }}
          >
            Kontakta Oss
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{ color: '#555', fontSize: { xs: '14px', md: '16px' }, textAlign: 'center' }}
          >
            Vi ser fram emot att höra från dig! Om du har några frågor eller vill veta
            mer om våra tjänster, fyll i formuläret nedan så återkommer vi till dig
            så snart som möjligt.
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mb: 3 }}>
            <TextField
              fullWidth
              label="Namn"
              variant="outlined"
              name="name"
              value={formData.name}
              onChange={handleChange}
              sx={{
                mb: 2,
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                '& input': {
                  padding: '12px',
                },
              }}
            />
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              name="email"
              value={formData.email}
              onChange={handleChange}
              sx={{
                mb: 2,
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                '& input': {
                  padding: '12px',
                },
              }}
            />
            <TextField
              fullWidth
              label="Telefonnummer"  // Added phone number input
              variant="outlined"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              sx={{
                mb: 2,
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                '& input': {
                  padding: '12px',
                },
              }}
            />
            <TextField
              fullWidth
              label="Meddelande"
              variant="outlined"
              multiline
              rows={4}
              name="message"
              value={formData.message}
              onChange={handleChange}
              sx={{
                mb: 2,
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                '& textarea': {
                  padding: '12px',
                },
              }}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: '#007C85',
                color: '#fff',
                fontWeight: '600',
                padding: '0.75rem 1.5rem',
                textTransform: 'none',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'background-color 0.3s ease',
                '&:hover': {
                  backgroundColor: '#005f73',
                },
                '&:active': {
                  transform: 'scale(0.98)',
                },
              }}
            >
              Skicka
            </Button>
          </Box>
        </Grid>
        {/* Contact Image Section */}
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              backgroundImage: `url(${contactImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: { xs: '300px', md: '400px' },
              borderRadius: '12px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              overflow: 'hidden',
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center',
              padding: '1rem',
            }}
          >
            {/* Optional text or overlay here */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;
