import { AppBar, Toolbar, Button, Box, IconButton, Menu, MenuItem, Slide, useScrollTrigger } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useState, useEffect } from 'react';
import { getImageUrl } from '../../utils';

const logo = getImageUrl('pilates-logo-blue.png');

function HideOnScroll({ children }) {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const Navbar = () => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // Scroll to the top whenever the location (route) changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <HideOnScroll>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: '#fff',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          padding: { xs: '0.75rem 0', md: '1rem 0' },
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Box>
            <Link to="/">
              <img src={logo} alt="Pilates Höganäs" style={{ height: '48px', width: 'auto', cursor: 'pointer' }} />
            </Link>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
            {['Startsida', 'Medlemsskap', 'Klasser', 'Personlig Träning', 'Bra Att Veta', 'Om Pilates Höganäs'].map(
              (item, index) => (
                <Button
                  key={index}
                  component={Link}
                  to={
                    item === 'Medlemsskap'
                      ? '/sidepages/membership-info'
                      : item === 'Om Pilates Höganäs'
                      ? '/sidepages/about-info'
                      : item === 'Klasser'
                      ? '/sidepages/klasses'
                      : item === 'Personlig Träning'
                      ? '/sidepages/personaltraining-info'
                      : item === 'Bra Att Veta'
                      ? '/sidepages/bra-att-veta'
                      : '/'
                  }
                  sx={{
                    color:
                      location.pathname ===
                      (item === 'Medlemsskap'
                        ? '/sidepages/membership-info'
                        : item === 'Om Pilates Höganäs'
                        ? '/sidepages/about-info'
                        : item === 'Klasser'
                        ? '/sidepages/klasses'
                        : item === 'Personlig Träning'
                        ? '/sidepages/personaltraining-info'
                        : item === 'Bra Att Veta'
                        ? '/sidepages/bra-att-veta'
                        : '/')
                        ? '#007C85'
                        : '#00b5bf',
                    marginLeft: '1.5rem',
                    fontWeight:
                      location.pathname ===
                      (item === 'Medlemsskap'
                        ? '/sidepages/membership-info'
                        : item === 'Om Pilates Höganäs'
                        ? '/sidepages/about-info'
                        : item === 'Klasser'
                        ? '/sidepages/klasses'
                        : item === 'Personlig Träning'
                        ? '/sidepages/personaltraining-info'
                        : item === 'Bra Att Veta'
                        ? '/sidepages/bra-att-veta'
                        : '/')
                        ? 'bold'
                        : 'normal',
                    fontSize: '1.1rem',
                    transition: 'color 0.3s ease, transform 0.3s ease',
                    '&:hover': {
                      color: '#007C85',
                      transform: 'scale(1.05)',
                    },
                  }}
                >
                  {item}
                </Button>
              )
            )}
            <Button
              href="https://pilateshoganas.wondr.se/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: '#fff',
                marginLeft: '1.5rem',
                backgroundColor: '#00b5bf',
                padding: '0.75rem 2rem',
                borderRadius: '16px',
                fontWeight: '600',
                textTransform: 'none',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                transition: 'all 0.3s ease',
                '&:hover': {
                  backgroundColor: '#007C85',
                  transform: 'translateY(-2px)',
                },
              }}
            >
              Logga in
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
              sx={{ color: '#00b5bf', fontSize: '32px' }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              sx={{ display: { xs: 'block', md: 'none' } }}
            >
              {['Startsida', 'Medlemsskap', 'Om Pilates Höganäs', 'Klasser', 'Personlig Träning', 'Bra Att Veta'].map(
                (item, index) => (
                  <MenuItem
                    key={index}
                    component={Link}
                    to={
                      item === 'Medlemsskap'
                        ? '/sidepages/membership-info'
                        : item === 'Om Pilates Höganäs'
                        ? '/sidepages/about-info'
                        : item === 'Klasser'
                        ? '/sidepages/klasses'
                        : item === 'Personlig Träning'
                        ? '/sidepages/personaltraining-info'
                        : item === 'Bra Att Veta'
                        ? '/sidepages/bra-att-veta'
                        : '/'
                    }
                    onClick={handleClose}
                    sx={{
                      padding: '0.75rem 1.5rem',
                      fontSize: '1rem',
                    }}
                  >
                    {item}
                  </MenuItem>
                )
              )}
            </Menu>
            <Button
              href="https://pilateshoganas.wondr.se/"
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                color: '#fff',
                backgroundColor: '#00b5bf',
                padding: '0.5rem 1.5rem',
                borderRadius: '20px',
                fontWeight: '600',
                textTransform: 'none',
                marginLeft: '0.5rem',
                '&:hover': {
                  backgroundColor: '#007C85',
                },
              }}
            >
              Logga in
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default Navbar;
