import { Box, Typography } from '@mui/material';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getImageUrl } from '../../utils';

const images = [
  { src: getImageUrl('hero-19.png'), alt: 'Pilates 8' },
  { src: getImageUrl('new-hero12.png'), alt: 'Pilates 3' },
  { src: getImageUrl('new-hero1.png'), alt: 'Pilates 8' },
  { src: getImageUrl('hero-18.png'), alt: 'Pilates 8' },
  { src: getImageUrl('new-hero2.png'), alt: 'Pilates 7' },
  { src: getImageUrl('new-hero4.png'), alt: 'Pilates 8' },
  { src: getImageUrl('new-hero5.png'), alt: 'Pilates 8' },
  { src: getImageUrl('new-hero6.png'), alt: 'Pilates 2' },
  { src: getImageUrl('hero-11.png'), alt: 'Pilates 2' },
  { src: getImageUrl('new-hero10.png'), alt: 'Pilates 3' },
  { src: getImageUrl('new-hero14.png'), alt: 'Pilates 3' },
  { src: getImageUrl('new-hero7.png'), alt: 'Pilates 4' },
  { src: getImageUrl('hero-14.png'), alt: 'Pilates 5' },
  { src: getImageUrl('new-hero11.png'), alt: 'Pilates 3' },
  { src: getImageUrl('hero-15.png'), alt: 'Pilates 6' },
  { src: getImageUrl('new-hero13.png'), alt: 'Pilates 3' },
  { src: getImageUrl('hero-5.png'), alt: 'Pilates 8' },
  { src: getImageUrl('hero-20.png'), alt: 'Pilates 8' },
  
];

const Hero = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    arrows: false,
  };

  return (
    <Box
      sx={{
        width: '100%',
        overflow: 'hidden', // Hides scrollbar on mobile
        position: 'relative',
        '& .slick-slider': {
          display: 'block',
        },
      }}
    >
      <Slider {...settings}>
        {images.map((image, index) => (
          <Box
            key={index}
            sx={{
              position: 'relative',
              paddingTop: { xs: '56.25%', md: '42.86%' }, // Adjusted aspect ratio: 16:9 for mobile, 21:9 for desktop
              backgroundImage: `url(${image.src})`,
              backgroundSize: 'cover', // Maintain image aspect ratio while covering container
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              width: '100%',
              height: '0',
            }}
          >
            <Typography 
              variant="h3" 
              sx={{ 
                position: 'absolute', 
                top: '15%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 2, 
                color: '#FFF', // Bright white text for contrast
                fontWeight: 'bold',
                textAlign: 'center', // Centered text alignment
                textTransform: 'uppercase', // Makes the text more impactful
                textShadow: '3px 3px 15px rgba(0, 0, 0, 0.8)', // Stronger text shadow
                padding: { xs: '0 1rem', md: '0 2rem' },
                fontSize: { xs: '2rem', md: '3rem' }, // Adjusted for responsiveness
                letterSpacing: '0.2rem',
                animation: 'fadeIn 2s ease-in-out', // Smooth fade-in animation
                '@keyframes fadeIn': {
                  from: { opacity: 0 },
                  to: { opacity: 1 },
                },
              }}
            >
              Pilates Höganäs
            </Typography>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Hero;
