// src/components/sidepages/InfoRegler.js
import React from 'react';
import { Container, Typography, Box, Button, Grid } from '@mui/material';
import { getImageUrl } from '../../utils'; // Assuming you have this utility

// Importing Icons from Material UI
import TimerIcon from '@mui/icons-material/Timer';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DiscountIcon from '@mui/icons-material/Discount';
import WarningIcon from '@mui/icons-material/Warning';
import HelpIcon from '@mui/icons-material/Help';
import SportsGymnasticsIcon from '@mui/icons-material/SportsGymnastics';
import FmdBadIcon from '@mui/icons-material/FmdBad';

const BraAttVeta = () => {
  return (
    <Container sx={{ padding: '4rem 0' }}>
      <Typography
        variant="h3"
        align="center"
        sx={{ 
          fontWeight: 'bold', 
          marginBottom: '2rem', 
          color: '#333',
          fontSize: { xs: '2.2rem', md: '2rem' }
        }}
      >
        Information som är bra för dig att veta
      </Typography>

      <Grid container spacing={4}>
        {/* Section  - Välj rätt klass */}
      <Grid item xs={12} md={6}>
          <Box 
            sx={{ 
              padding: '2rem', 
              backgroundColor: '#f5f5f5', 
              borderRadius: '12px', 
              textAlign: 'center',
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)'
            }}
          >
            <HelpIcon sx={{ fontSize: '4rem', color: '#A2257C' }} />
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: '1rem' }}>
            Välj Rätt Klass
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '0.5rem' }}>
            Blir ni osäkra vilka klasser ni ska välja, gå in och kolla klassbeskrivning eller fråga er instruktör. Vi finns här för er skull.
            </Typography>
          </Box>
        </Grid>
        {/* Section 2 - Personal Training Cancellation */}
        <Grid item xs={12} md={6}>
          <Box 
            sx={{ 
              padding: '2rem', 
              backgroundColor: '#f5f5f5', 
              borderRadius: '12px', 
              textAlign: 'center',
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)'
            }}
          >
            <TimerIcon sx={{ fontSize: '4rem', color: '#A2257C' }} />
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: '1rem' }}>
              Personlig Träning
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '0.5rem' }}>
              Personlig träning ska avbokas senast 24 timmar innan, annars debiteras hela kostnaden.
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box 
            sx={{ 
              padding: '2rem', 
              backgroundColor: '#f5f5f5', 
              borderRadius: '12px', 
              textAlign: 'center',
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)'
            }}
          >
            <FmdBadIcon sx={{ fontSize: '4rem', color: '#A2257C' }} />
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: '1rem' }}>
              Strumpor
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '0.5rem' }}>
            Observera att alla deltagare måste bära strumpor under klasserna. <br/> Vi har strumpor till försäljning i olika prisklasser!
            </Typography>
          </Box>
        </Grid>

        {/* Section 2 - Class Cancellation Policy */}
        <Grid item xs={12} md={6}>
          <Box 
            sx={{ 
              padding: '2rem', 
              backgroundColor: '#f5f5f5', 
              borderRadius: '12px', 
              textAlign: 'center',
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)'
            }}
          >
            <PeopleAltIcon sx={{ fontSize: '4rem', color: '#A2257C' }} />
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: '1rem' }}>
              Klass Avbokning
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '0.5rem' }}>
              Vi förbehåller oss rätten att ställa in klasser vid för få deltagare.
            </Typography>
          </Box>
        </Grid>

        {/* Section 3 - Discount for Age Groups */}
        <Grid item xs={12} md={6}>
          <Box 
            sx={{ 
              padding: '2rem', 
              backgroundColor: '#f5f5f5', 
              borderRadius: '12px', 
              textAlign: 'center',
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)'
            }}
          >
            <DiscountIcon sx={{ fontSize: '4rem', color: '#A2257C' }} />
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: '1rem' }}>
              Visdomsrabatt
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '0.5rem' }}>
              Visdomsrabatt får de som inte fyllt 18 år än och de som fyllt 65 år.
            </Typography>
          </Box>
        </Grid>

        {/* Section 4 - Reformer Safety Info */}
        <Grid item xs={12} md={6}>
          <Box 
            sx={{ 
              padding: '2rem', 
              backgroundColor: '#f5f5f5', 
              borderRadius: '12px', 
              textAlign: 'center',
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)'
            }}
          >
            <SportsGymnasticsIcon sx={{ fontSize: '4rem', color: '#A2257C' }} />
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: '1rem' }}>
              Reformer Klasser
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '0.5rem' }}>
              Reformer klasser är för dem som gått intro Reformer el tränar PT och har kunskap om ”safety” på Reformern.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box 
            sx={{ 
              padding: '2rem', 
              backgroundColor: '#f5f5f5', 
              borderRadius: '12px', 
              textAlign: 'center',
              boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)'
            }}
          >
            <WarningIcon sx={{ fontSize: '4rem', color: '#A2257C' }} />
            <Typography variant="h5" sx={{ fontWeight: 'bold', marginTop: '1rem' }}>
            Åldersgräns
            </Typography>
            <Typography variant="body1" sx={{ marginTop: '0.5rem' }}>
            Vi har 15-års gräns i studion.            </Typography>
          </Box>
        </Grid>    
      </Grid>

      <Box sx={{ textAlign: 'center', marginTop: '3rem' }}>
        <Button
          sx={{
            backgroundColor: '#00b5bf',
            color: '#fff',
            padding: '0.75rem 1.5rem',
            fontSize: '1.1rem',
            fontWeight: '600',
            borderRadius: '12px',
            textTransform: 'none',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.5)',
            '&:hover': {
              backgroundColor: '#429398',
            },
          }}
          href="/sidepages/klasses"
        >
          Se Alla Klasser
        </Button>
      </Box>
      <Box sx={{ textAlign: 'center', marginTop: '3rem' }}>
        <Button
          sx={{
            backgroundColor: '#A2257C',
            color: '#fff',
            padding: '0.75rem 1.5rem',
            fontSize: '1rem',
            fontWeight: '600',
            borderRadius: '12px',
            textTransform: 'none',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.4)',
            '&:hover': {
              backgroundColor: '#A2557C',
            },
          }}
          href="/sidepages/membership-info"
        >
          Köp Medlemskap
        </Button>
      </Box>
    </Container>
  );
};

export default BraAttVeta;
